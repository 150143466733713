<template>
    <div class="card">
        <div class="card-body">
          <template v-if="car">
            <h4 class="card-title">Scheduled rentals for <span class="text-danger">{{car['name']}}</span> </h4>
            <div class="row mb-md-2 mt-4">

              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>


              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                  :items="scheduledList"
                  :fields="fields"
                  v-model="currentItems"
                  ref="selectableTable"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
              >

                <template v-slot:cell(rentedBy)="row">
                  {{row.item['driver']['name']}} {{row.item['driver']['other_names']}}
                </template>

                <template v-slot:cell(from)="row">
                  {{toReadableDate(row.item['start_date'])}}
                </template>

                <template v-slot:cell(to)="row">
                  {{toReadableDate(row.item['end_date'])}}
                </template>

                <template v-slot:cell(created_at)="row">
                  {{ toReadableDate(row.item['created_at']) }}
                </template>

                <template v-slot:cell(status)="row">
                  <span v-if="row.item['status'] === 'assigned'" class="text-success">{{ row.item['status']}}</span>
                  <span v-else class="text-danger">{{ row.item['status'] }}</span>
                </template>

                <template v-slot:cell(departure_controls_status)="row">
                  <span v-if="row.item['departure_controls_status'] === null" class="text-danger">Not completed</span>
                  <span v-else class="text-success">{{ row.item['departure_controls_status'] }}</span>
                </template>

                <template v-slot:cell(remarks)="row">
                  <span v-if="row.item['status'] === 'assigned' && row.item['cost_remaining'] === '0.00' && !row.item['arrival_controls_status']" class="text-danger">Requires arrival control</span>
                  <span v-else-if="row.item['status'] === 'assigned' && row.item['cost_remaining'] > 0.00 && !row.item['arrival_controls_status']" class="text-success">In use</span>
                  <span v-else class="text-muted">None</span>
                </template>

                <template v-slot:cell(arrival_controls_status)="row">
                  <span v-if="row.item['arrival_controls_status'] === null" class="text-muted">Not completed</span>
                  <span v-else class="text-danger">{{ row.item['arrival_controls_status'] }}</span>
                </template>


              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </template>
        </div>
    </div>
</template>

<script>
import {rentalsService} from "../../../../apis/rentals.service";
import moment from "moment";

export default {
  name: "car-rental-list",
  data() {
    return {
      scheduledList: [],
      car: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      currentItems: [],
      selectedItems: [],
      sortBy: "created_at",
      sortDesc: false,
      fields: [
        {
          key: 'rentedBy',
          label: 'Rented by'
        },
        {
          key: 'from',
          label: 'Rented from'
        },
        {
          key: 'to',
          label: 'Rented to'
        },
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'remarks',
          label: 'Remarks'
        },
        {
          key: 'departure_controls_status',
          label: 'Departure Control Status'
        },
        {
          key: 'arrival_controls_status',
          label: 'Arrival Control Status'
        },
        {
          key: "created_at",
          label: "Date created",
          sortable: true,
        }
      ],

    }
  },
  computed: {
    rows() {
      return this.scheduledList.length;
    },
  },
  methods: {
    fetchScheduledRentals(){
        const carId = this.$route.params['id']
        this.$store.dispatch('showLoader')
        rentalsService.fetchScheduledRentalList(carId).then((data) => {
          this.$store.dispatch('hideLoader')
          if(!data.status){
            this.$store.dispatch('error', { message: data.message, showSwal: true})
            return;
          }
          // scheduledRentalList
          this.car = data.extra['car']
          this.scheduledList = data.extra['scheduledRentalList']

          console.log('scheduledRentalList', this.scheduledList)

        })
    },
    toReadableDate(rawDate){
      return moment(rawDate).calendar()
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {
    this.fetchScheduledRentals()
  }
}
</script>

<style scoped>

</style>